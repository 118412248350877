import { ref } from 'vue';
import { getAllTags } from '@/api/settings/tags';

// 标签列表
const tagList = ref([]);

// 调接口获取下拉列表
const fetchTagList = async () => {
  const res = await getAllTags();
  tagList.value = res.data;
};

// 当前选中项
const selectedTag = ref<string | Array<string>>();

// 下拉列表change事件
const handleTagChange = (val: string | Array<string>) => {
  selectedTag.value = val;
};

// 获取当前的标签名称
const getTagName = (ids: string) =>
  ids
    .split(',')
    .map((id) => {
      const tag = tagList.value.find((i: any) => i.id === Number(id));
      return (tag as any)?.name;
    })
    .join(',');

export const useTags = (initList = false) => {
  if (initList) {
    fetchTagList();
  }
  return {
    tagList,
    selectedTag,
    getTagName,
    handleTagChange,
  };
};
