<template>
  <el-dialog title="引入" v-model="visible" width="40%" @close="handleClose">
    <el-form :model="serviceForm" label-position="left" ref="formRef" :rules="rules">
      <el-form-item :label="formLabel" label-width="120px" prop="list">
        <el-cascader
          filterable
          style="width: 80%"
          :placeholder="placeholder"
          v-model="serviceForm.list"
          :options="serviceList"
          :props="serviceCascaderProps"
          @change="nodeChange"
        >
          <template #default="{ data }">
            <span v-if="data.versionType">{{ data.value }}</span>
            <service-name v-else :name="data.value" />
          </template>
        </el-cascader>
      </el-form-item>
    </el-form>
    <div class="confirm-button">
      <el-button type="primary" @click="onConfirm" :loading="submitting">确认</el-button>
      <el-button @click="handleClose">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { reactive, ref } from 'vue';
import { deployToEnv, getAppList, getServiceList } from '@/api/env';
import { getServiceShowName } from '@/views/service-management/components/utils';
import { ElMessage } from 'element-plus';

export default {
  name: 'ImportDialog',
  props: {
    importType: String,
    currentValue: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['complete'],
  setup(props, { emit }) {
    const serviceForm = reactive({ list: '' });
    const formRef = ref();
    const visible = ref(false);
    const submitting = ref(false);

    const handleOpen = () => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getList();
      visible.value = true;
    };

    const handleClose = () => {
      visible.value = false;
      formRef.value.resetFields();
    };

    const deploy = async (data) => {
      const type = props.importType.indexOf('-app') != -1 ? 'app' : 'service';
      return deployToEnv(type, {
        data: {
          type,
          ...data,
        },
      });
    };

    const rules = {
      list: [{ required: true, message: '引入内容不能为空', trigger: 'change' }],
    };

    const onConfirm = async () => {
      const valid = await formRef.value.validate();
      if (!valid) {
        return false;
      }
      visible.value = false;
      const nodes = serviceForm.list.map((item) => {
        const [name, version] = item;
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const node = serviceList.value.find((s) => s.value === name);
        return {
          name,
          version,
          repositoryId: node.children.find((c) => c.value === version)?.repositoryId,
        };
      });
      submitting.value = true;
      try {
        const result = await Promise.allSettled(nodes.map((node) => deploy(node)));
        const rejectedRes = result.filter((res) => res.status === 'rejected');
        if (rejectedRes.length > 0) {
          throw new Error(`${rejectedRes.length}个服务引入失败`);
        }
        ElMessage.success('引入成功');
        handleClose();
        emit('complete');
      } catch (e) {
        ElMessage.error(e?.message || '引入失败');
      }
      submitting.value = false;
    };

    const placeholder = ref('');
    const formLabel = ref('');
    let api = null;
    switch (props.importType) {
      case 'service':
        placeholder.value = '请引入项目内的服务';
        formLabel.value = '服务列表';
        api = getServiceList;
        break;
      case 'merge-service':
        placeholder.value = '请引入项目内的合并服务';
        formLabel.value = '合并服务列表';
        api = getServiceList;
        break;
      case 'main-app':
        placeholder.value = '请引入项目内的应用';
        formLabel.value = '应用列表';
        api = () =>
          getAppList({
            params: {
              appLevel: 0,
            },
          });
        break;
      case 'micro-app':
        placeholder.value = '请选择模块和版本';
        formLabel.value = '微应用列表';
        api = () =>
          getAppList({
            params: {
              appLevel: 1,
            },
          });
        break;
    }

    const serviceList = ref([]);
    const getList = async () => {
      let { data } = await api();
      if (props.importType === 'service') {
        data = data.filter((x) => x.appearance !== 4);
      } else if (props.importType === 'merge-service') {
        data = data.filter((x) => x.appearance === 4);
      }
      serviceList.value = data.map((i) => ({
        label: getServiceShowName(i.serviceName),
        value: i.serviceName,
        children: i.versions?.map((v) => ({
          value: v.version,
          label: v.versionStatus !== 10 ? `${v.version}(${serviceStatus[v.versionStatus]})` : v.version,
          versionType: v.versionType,
          disabled: v.versionStatus !== 10,
          repositoryId: v.repositoryId,
        })),
        origin: i,
      }));
    };

    const nodeChange = (nodes) => {
      const checkNode = {};
      for (const node of nodes) {
        checkNode[node[0]] = node;
      }
      const selectData = Object.values(checkNode);
      serviceForm.list = selectData;
    };

    const serviceCascaderProps = ref({
      multiple: true,
    });

    return {
      visible,
      serviceForm,
      formRef,
      rules,
      placeholder,
      formLabel,
      serviceList,
      getList,
      handleOpen,
      handleClose,
      onConfirm,
      nodeChange,
      serviceCascaderProps,
      submitting,
    };
  },
};
</script>

<style lang="scss" scoped>
.confirm-button {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
</style>
