import { ref } from 'vue';
import { getClassificationList } from '@/api/settings/classification';
import { deleteBlankArray } from '@/views/service-management/business-service/utils/service-data-utils';

const classificationList = ref([]);

// 选择器双向绑定的值
const selectedClassification = ref();

// 选择器onChange触发
const handleClassificationChange = (val: Array<string>) => {
  selectedClassification.value = val;
};

// 调接口获取分类列表
const fetchList = async () => {
  const res = await getClassificationList();
  const ids = { children: res.data };
  deleteBlankArray(ids);
  classificationList.value = ids.children;
};

// table渲染时获取对应的分类名
const getClassificationName = (ids: string) =>
  ids
    .split(',')
    .map((id) => {
      const classification = classificationList.value?.find((i: any) => i.id === Number(id));
      return (classification as any)?.name;
    })
    .join(',');

export const useClassification = (initList = false) => {
  if (initList) {
    fetchList();
  }
  return {
    classificationList,
    selectedClassification,
    handleClassificationChange,
    getClassificationName,
  };
};
