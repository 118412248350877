<template>
  <i class="el-icon-search"></i>
  <el-popover placement="bottom" :width="200" trigger="manual" :visible="visible">
    <template #reference>
      <el-button type="text" @click="handleFilterOpen">分类</el-button>
    </template>
    <el-cascader
      v-model="selectedClassification"
      :options="classificationList"
      :props="classificationProps"
      clearable
      filterable
      @change="handleChange"
      placeholder="请选择分类"
    ></el-cascader>
  </el-popover>
  <div class="black-hovers" @click="handleOverlayClick()" v-if="visible"></div>
</template>

<script>
import { useClassification } from '../utils/classification';
import { ref } from 'vue';

export default {
  name: 'ClassificationFilter',
  emits: ['change', 'change-close'],
  setup(props, { emit }) {
    const { selectedClassification, handleClassificationChange, classificationList } = useClassification(true);
    const visible = ref(false);

    // 选择器change事件
    const handleChange = (value) => {
      handleClassificationChange(value);
      emit('change', value);
    };

    // 点击打开级联选择器
    const handleFilterOpen = () => {
      visible.value = true;
    };

    // 级联选择器属性配置
    const classificationProps = {
      label: 'name',
      value: 'id',
      emitPath: false,
    };

    // 控制遮罩层
    const handleOverlayClick = () => {
      visible.value = false;
      emit('change-close', selectedClassification.value);
    };
    return {
      handleChange,
      selectedClassification,
      visible,
      handleFilterOpen,
      classificationProps,
      classificationList,
      handleOverlayClick,
    };
  },
};
</script>
