import { setEnvStorage } from '@/utils/env-storage';
import { ref } from 'vue';
import { useRoute } from 'vue-router';

export const useEnvId = () => {
  const envId = ref(useRoute().params.id);
  if (envId.value) {
    setEnvStorage(Number(envId.value));
  }
  return envId;
};

/**
 * 从环境进入服务或者应用详情
 * @returns
 */
export const useEnvAndServiceId = () => {
  const route = useRoute();
  const { envId, serviceId, id, env: paramEnv } = route.params;
  const { env, envName } = route.query;
  let routeServiceId = Number(id);
  if (envId) {
    setEnvStorage(Number(envId));
    routeServiceId = Number(serviceId);
  }
  console.log(env, paramEnv);
  return {
    serviceId: routeServiceId,
    envId,
    env: ((paramEnv || env || '') as string).toUpperCase(),
    envName,
  };
};
