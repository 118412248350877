<template>
  <i class="el-icon-search"></i>
  <el-popover placement="bottom" :width="200" trigger="manual" :visible="visible">
    <template #reference>
      <el-button type="text" @click="handleFilterOpen">标签</el-button>
    </template>
    <el-select v-model="selectedTag" placeholder="请选择标签" clearable multiple @change="handleChange">
      <el-option v-for="(item, index) in tagList" :key="index" :label="item.name" :value="item.id"></el-option>
    </el-select>
  </el-popover>
  <div class="black-hovers" @click="handleOverlayClick()" v-if="visible"></div>
</template>

<script>
import { ref } from 'vue';
import { useTags } from '../utils/tags';

export default {
  name: 'TagFilter',
  emits: ['change', 'change-close'],
  setup(props, { emit }) {
    const { tagList, selectedTag, handleTagChange } = useTags(true);

    const visible = ref(false);
    const handleFilterOpen = () => {
      visible.value = true;
    };

    // 下拉框change事件
    const handleChange = (value) => {
      handleTagChange(value);
      emit('change', value);
    };

    // 遮罩层控制
    const handleOverlayClick = () => {
      emit('change-close', selectedTag.value);
      visible.value = false;
    };

    return {
      visible,
      handleFilterOpen,
      handleOverlayClick,
      tagList,
      selectedTag,
      handleTagChange,
      handleChange,
    };
  },
};
</script>
