<template>
  <el-dialog
    :title="deployForm.isEdit ? '编辑' : '部署申请'"
    v-model="visible"
    width="600px"
    @closed="handleFormCancel"
  >
    <div class="add-publish-set">
      <el-form :model="deployForm.formData" :rules="deployFormRules" ref="deployFormRef">
        <el-form-item label="部署类型" prop="deployType" label-width="100px">
          <el-select
            v-model="deployForm.formData.deployType"
            placeholder="请选择部署类型"
            clearable
            :disabled="deployForm.disabled || isFixedType"
            @change="handleDeployTypeChange"
          >
            <el-option
              v-for="item in deployTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="deployTypeLabel" prop="name" label-width="100px">
          <el-select
            v-model="deployForm.formData.deployName"
            :placeholder="deployTypeLabel"
            :disabled="deployForm.disabled"
            @change="handleServiceOrAppChange"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in deployForm.serviceOrAppList"
              :key="index"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="申请账号" prop="applicantName" label-width="100px">
          <el-input v-model="deployForm.formData.applicantName" :disabled="true"></el-input>
        </el-form-item>

        <el-form-item label="部署版本" prop="deployVersion" label-width="100px">
          <el-select
            v-model="deployForm.formData.deployVersion"
            placeholder="请选择部署版本"
            clearable
            :disabled="deployForm.disabled"
          >
            <el-option
              v-for="(item, index) in deployForm.versionOptions"
              :key="index"
              :label="item.label"
              :value="item.name"
              :disabled="item.versionStatus !== 10"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="部署说明" prop="deployDescription" label-width="100px">
          <el-input
            v-model="deployForm.formData.deployDescription"
            type="textarea"
            :rows="8"
            maxlength="1024"
            placeholder="请输入部署说明"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="handleFormSubmit">确定</el-button>
        <el-button @click="handleFormCancel">取消</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { computed, reactive, ref } from 'vue';
import { userInfo } from '@/layout/messageCenter/user-info';
import { ElMessage } from 'element-plus';
import { addDeployApply, getAppListForDeploy, getServiceListForDeploy, updateDeployApply } from '@/api/env';

export default {
  name: 'DeployDialog',
  props: {
    deployType: {
      type: String,
      default: 'apply',
    },
  },
  setup(props, { emit }) {
    const visible = ref(false);
    const deployFormRef = ref();

    const deployForm = reactive({
      disabled: false,
      isEdit: false,
      id: '',
      formData: {
        deployType: props.deployType === 'apply' ? 'service' : props.deployType,
        deployName: '',
        applyUser: userInfo.value.userId,
        applicantName: `${userInfo.value.displayName}_${userInfo.value.userName}`,
        deployVersion: '',
        deployDescription: '',
      },
      serviceSources: [],
      serviceOrApzpList: [],
      versionOptions: [],
    });

    const deployTypeList = ref([
      { value: 'main-app', label: '主应用' },
      { value: 'micro-app', label: '前端模块' },
      { value: 'service', label: '服务' },
      { value: 'merge-service', label: '合并部署' },
    ]);

    const isFixedType = computed(() => props.deployType !== 'apply');
    const searchProps = reactive({
      keyword: '',
      status: null,
      auditResults: null,
      page: 1,
      pageSize: 10,
      sortField: 'createTime',
      sortType: 'descending',
    });

    const deployFormRules = {
      deployType: [{ required: true, message: '请选择部署类型', trigger: 'change' }],
      name: [{ required: true, message: '请输入部署名称', trigger: 'change' }],
      applicant: [{ required: true, message: '请选择申请账号', trigger: 'change' }],
      deployVersion: [{ required: true, message: '请选择部署版本', trigger: 'change' }],
      deployDescription: [{ required: true, message: '请输入部署说明', trigger: 'blur' }],
    };

    const deployTypeLabel = ref();
    let api = null;
    const deployTypeChange = (type) => {
      switch (type) {
        case 'main-app':
          deployTypeLabel.value = '主应用名称';
          api = () =>
            getAppListForDeploy({
              params: {
                appLevel: 0,
              },
            });
          break;
        case 'micro-app':
          deployTypeLabel.value = '前端模块名称';
          api = () =>
            getAppListForDeploy({
              params: {
                appLevel: 1,
              },
            });
          break;
        case 'service':
          deployTypeLabel.value = '服务名称';
          api = getServiceListForDeploy;
          break;
        case 'merge-service':
          deployTypeLabel.value = '合并服务名称';
          api = getServiceListForDeploy;
          break;
      }
    };
    deployTypeChange(deployForm.formData.deployType);

    // 获取服务/应用列表
    const fetchList = async () => {
      const { data } = await api();
      deployForm.serviceSources = data;
      if (deployForm.formData.deployType === 'service') {
        return (deployForm.serviceOrAppList = data
          .map((item) => (item.appearance !== 4 ? { name: item.serviceName } : undefined))
          .filter((x) => x));
      }
      if (deployForm.formData.deployType === 'merge-service') {
        return (deployForm.serviceOrAppList = data
          .map((item) => (item.appearance === 4 ? { name: item.serviceName } : undefined))
          .filter((x) => x));
      }
      return (deployForm.serviceOrAppList = data.map((item) => ({
        name: item.serviceName,
      })));
    };

    const handleDeployTypeChange = (val) => {
      deployFormRef.value.resetFields();
      deployForm.formData.deployType = val;
      deployTypeChange(deployForm.formData.deployType);
      fetchList();
    };

    const handleServiceOrAppChange = (val) => {
      const data = deployForm.serviceOrAppList.find((item) => item.name === val);
      deployForm.formData.name = data?.name;
      const temp = deployForm.serviceSources.find((item) => item.serviceName === deployForm.formData.name);
      deployForm.versionOptions = temp.versions.map((item) => {
        const label = ref('');
        const statusStr = ['（发版成功）', '（发版中）', '（发版失败）', '（等待依赖发版）'];
        if (item.versionStatus === 10) {
          label.value = `${item.version}${statusStr[0]}`;
        } else if (item.versionStatus === 1) {
          label.value = `${item.version}${statusStr[1]}`;
        } else if (item.versionStatus === 2) {
          label.value = `${item.version}${statusStr[2]}`;
        } else {
          label.value = `${item.version}${statusStr[3]}`;
        }
        return {
          name: item.version,
          label: label.value,
          versionStatus: item.versionStatus,
        };
      });
    };

    const handleFormCancel = () => {
      deployForm.formData.deployName = '';
      deployForm.versionOptions = [];
      deployTypeChange(deployForm.formData.deployType);
      deployFormRef.value.resetFields();
      visible.value = false;
    };

    const deployTypeMap = {
      service: 1,
      'micro-app': 2,
      'main-app': 2,
      'merge-service': 1,
    };

    // 表单编辑状态
    const handleEdit = (row) => {
      visible.value = true;
      deployForm.isEdit = true;
      deployForm.disabled = true;
      deployForm.id = row.id;
      deployForm.formData = {
        ...deployForm.formData,
        ...row,
      };
    };

    // 提交表单
    const handleFormSubmit = () => {
      deployFormRef.value.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        // 新建或者编辑态
        if (!deployForm.isEdit) {
          try {
            const { code } = await addDeployApply({
              ...deployForm.formData,
              deployType: deployTypeMap[deployForm.formData.deployType],
            });
            if (code === 0) {
              ElMessage({
                type: 'success',
                message: '添加成功',
              });
            } else {
              ElMessage({
                type: 'error',
                message: '添加失败',
              });
            }
            handleFormCancel();
            emit('success-add');
          } catch (e) {
            throw new Error(e);
          }
        } else {
          try {
            const { code } = await updateDeployApply(deployForm.id, {
              deployDescription: deployForm.formData.deployDescription,
            });
            if (code === 0) {
              ElMessage({
                type: 'success',
                message: '编辑成功',
              });
            } else {
              ElMessage({
                type: 'error',
                message: '编辑失败',
              });
            }
            handleFormCancel();
            emit('success-add');
          } catch (e) {
            throw new Error(e);
          }
        }
      });
    };

    const handleOpen = () => {
      visible.value = true;
      fetchList();
    };

    return {
      visible,
      handleOpen,
      deployForm,
      searchProps,
      deployFormRules,
      handleDeployTypeChange,
      handleServiceOrAppChange,
      fetchList,
      deployFormRef,
      handleFormSubmit,
      handleFormCancel,
      handleEdit,
      deployTypeLabel,
      deployTypeList,

      isFixedType,
    };
  },
};
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
</style>
